// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kocici-kviz-tsx": () => import("./../../../src/pages/kocici-kviz.tsx" /* webpackChunkName: "component---src-pages-kocici-kviz-tsx" */),
  "component---src-pages-mazlicci-a-covid-tsx": () => import("./../../../src/pages/mazlicci-a-covid.tsx" /* webpackChunkName: "component---src-pages-mazlicci-a-covid-tsx" */),
  "component---src-pages-prvni-rok-kocka-tsx": () => import("./../../../src/pages/prvni-rok-kocka.tsx" /* webpackChunkName: "component---src-pages-prvni-rok-kocka-tsx" */),
  "component---src-pages-prvni-rok-pes-tsx": () => import("./../../../src/pages/prvni-rok-pes.tsx" /* webpackChunkName: "component---src-pages-prvni-rok-pes-tsx" */),
  "component---src-pages-psi-kviz-tsx": () => import("./../../../src/pages/psi-kviz.tsx" /* webpackChunkName: "component---src-pages-psi-kviz-tsx" */)
}

